import React, { createContext, useContext, useEffect, useState } from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "../apollo/client"
import { AuthProvider } from "../context/authContext"

// Create a context
const MyContext = createContext()

// Custom hook to use the context
export const useMyContextLocalStorage = () => {
  return useContext(MyContext)
}

// Define the context provider
const MyContextProvider = ({ children }) => {
  const [myLocalStorageItem, setMyLocalStorageItem] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const value = localStorage.getItem("dalia.auth.login")
      if (value) {
        setMyLocalStorageItem(true)
      } else {
        setMyLocalStorageItem(false)
      }
    }
  }, [])

  return (
    <MyContext.Provider value={{ myLocalStorageItem, setMyLocalStorageItem }}>
      {children}
    </MyContext.Provider>
  )
}

// Wrap the root element with the context provider and ApolloProvider
export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <MyContextProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </MyContextProvider>
  </AuthProvider>
)

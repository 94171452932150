import React, { useReducer, createContext, useEffect } from "react"
import jwtDecode from "jwt-decode"
import { client } from "../apollo/client"

const initialState = {
  user: null,
  userOnLive: false,
}

const AuthContext = createContext({
  user: null,
  userOnLive: false,
  login: userData => {},
  logout: () => {},
  setUserOnLive: isLive => {},
})

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      }
    case "LOGOUT":
      return {
        ...state,
        user: null,
        userOnLive: false,
      }
    case "SET_USER_ON_LIVE":
      return {
        ...state,
        userOnLive: action.payload,
      }
    default:
      return state
  }
}

function AuthProvider(props) {
  const initialState = typeof window !== "undefined" ? {
    user: localStorage.getItem("isMember") || null,
    userOnLive: localStorage.getItem("isUserOnLive") === "true",
  } : {
    user: null,
    userOnLive: false,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("dalia.auth.login")
    if (token) {
      const decodedToken = jwtDecode(token)
      const expirationTime = decodedToken.exp * 1000
      const currentTime = Date.now()
      const timeUntilExpiration = expirationTime - currentTime

      if (timeUntilExpiration <= 0) {
        localStorage.removeItem("isMember")
        localStorage.removeItem("dalia.auth.login")
        localStorage.removeItem("refreshToken")
        dispatch({ type: "LOGOUT" })
        if (typeof client !== "undefined" && client.clearStore) {
          client.clearStore()
        }
      } else {
        setTimeout(checkTokenExpiration, timeUntilExpiration)
      }
    }
  }

  useEffect(() => {
    checkTokenExpiration()
  }, [])

  const login = userData => {
    if (typeof window !== "undefined") {
      localStorage.setItem("isMember", userData.token)
      dispatch({
        type: "LOGIN",
        payload: jwtDecode(userData.token),
      })
    }
  }

  const setUserOnLive = isLive => {
    if (typeof window !== "undefined") {
      localStorage.setItem("isUserOnLive", isLive)
      dispatch({
        type: "SET_USER_ON_LIVE",
        payload: isLive,
      })
    }
  }


  const logout = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("isMember")
      localStorage.removeItem("dalia.auth.login")
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("isUserOnLive")
      dispatch({ type: "LOGOUT" })
      if (typeof client !== "undefined" && client.clearStore) {
        client.clearStore()
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        userOnLive: state.userOnLive,
        login,
        logout,
        setUserOnLive,
      }}
      {...props}
    />
  )
}

export { AuthContext, AuthProvider }
